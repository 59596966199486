<template>
  <v-container grid-list-xl>
    <loading-wrapper :loading="loading">
      <v-layout wrap justify-space-between align-content-center>
        <v-flex shrink xs9>
          <view-title />
        </v-flex>
        <v-flex shrink>
          <div class="FAB-Extended-PrimaryColor-Enabled">
            <v-btn type="submit" flat @click="goToSettings">
              <v-icon left dark>mdi-settings</v-icon>Configuration
            </v-btn>
          </div>
        </v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex xs12>
          <filters
            :site="site"
            :locationTimeZone="locationTimeZone"
            :unitsSelected.sync="unitsSelected"
          />
        </v-flex>
      </v-layout>
      <v-layout>
        <loading-wrapper :loading="reportLoading">
          <v-flex xs12>
            <v-tabs hide-slider>
              <v-tab href="#summary">Summary</v-tab>
              <v-tab href="#distribution">Sales Distribution</v-tab>
              <v-tab-item value="summary">
                <v-card flat>
                  <readings
                    :dates="reportDates"
                    :report="selectedReport"
                    :locationTimeZone="locationTimeZone"
                  />
                  <div class="H6-Selected-On-Surface-Medium-Emphasis-Center" v-if="!selectedReport">
                    No reports available for selected units
                  </div>
                  <report-table v-bind="summary" />
                  <report-table v-bind="disbursements" />
                  <report-table v-bind="discounts" />
                  <report-table v-bind="fee" />
                  <report-table v-if="isWebReport" v-bind="web" />
                </v-card>
              </v-tab-item>
              <v-tab-item value="distribution">
                <v-card flat>
                  <div class="H6-Selected-On-Surface-Medium-Emphasis-Center" v-if="!selectedReport">
                    No reports available for selected units
                  </div>
                  <report-table v-bind="distribution"></report-table>
                  <report-table v-bind="locationOverview"></report-table>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-flex>
        </loading-wrapper>
      </v-layout>
      <div v-if="isMarketPlaceReport">
        <h5 class="Subtitle-1-Black-Medium-Emphasis-Left font-weight-normal">
          * NA - Sales distribution is not available for brands set up in a Marketplace
        </h5>
      </div>
    </loading-wrapper>
  </v-container>
</template>

<script>
import DateTime from 'luxon/src/datetime.js';
import cloneDeep from 'lodash/cloneDeep';
import mergeWith from 'lodash/mergeWith';
import defaultTo from 'lodash/defaultTo';
import add from 'lodash/add';

import { mapActions, mapState } from 'vuex';
import filters from './filters';
import readings from './readings';
import reportTable from './report-table';

export default {
  name: 'reportingDetails',
  components: {
    filters,
    readings,
    reportTable,
  },
  props: ['site_id', 'multigroup_id'],
  data: () => ({
    site: {},
    loading: false,
    reportLoading: false,
    report: null,
    unitsSelected: [],
  }),

  computed: {
    ...mapState('reports', ['reportDates']),
    summary() {
      if (!this.report || !this.selectedReport) {
        return null;
      }
      const data = {};
      data.title = 'Summary';
      const countriesArray = ['ca', 'can', 'canada'];
      if (countriesArray.includes(this.country)) {
        data.headers = [
          { title: 'Subtotal', type: 'price' },
          { title: 'Service Fees', type: 'price' },
          { title: 'Delivery Fees', type: 'price' },
          { title: 'Gross Sales', type: 'price' },
          { title: 'Discount/Contra Revenue', type: 'price' },
          { title: 'PST/QST', type: 'price' },
          { title: 'GST/HST', type: 'price' },
          { title: 'Net Sales', type: 'price' },
          { title: 'Count', type: 'number' },
        ];
        const {
          subtotal,
          service_fee,
          delivery_fee,
          gross,
          discount,
          pst,
          gst,
          net_sales,
          count,
        } = this.selectedReport.summary.summary;

        data.rows = [
          [subtotal, service_fee, delivery_fee, gross, discount, pst, gst, net_sales, count],
        ];
      } else {
        data.headers = [
          { title: 'Subtotal', type: 'price' },
          { title: 'Service Fees', type: 'price' },
          { title: 'Delivery Fees', type: 'price' },
          { title: 'Gross Sales', type: 'price' },
          { title: 'Discount/Contra Revenue', type: 'price' },
          { title: 'Sales Tax', type: 'price' },
          { title: 'Net Sales', type: 'price' },
          { title: 'Count', type: 'number' },
        ];
        const {
          subtotal,
          service_fee,
          delivery_fee,
          gross,
          discount,
          taxes,
          net_sales,
          count,
        } = this.selectedReport.summary.summary;

        data.rows = [
          [subtotal, service_fee, delivery_fee, gross, discount, taxes, net_sales, count],
        ];
      }
      return data;
    },
    disbursements() {
      if (!this.report || !this.selectedReport) {
        return null;
      }
      const data = {};
      data.title = 'Disbursements Summary';
      data.headers = [
        { title: 'Payment Type', type: 'text' },
        { title: 'Count', type: 'number' },
        { title: 'Register total', type: 'price' },
      ];
      const { disbursement } = this.selectedReport.summary;
      data.rows = Object.values(disbursement.types).map((e) => [e.type, e.num_orders, e.total]);
      data.rows.push(['Total', disbursement.num_orders, disbursement.total]);
      return data;
    },
    discounts() {
      if (!this.report || !this.selectedReport) {
        return null;
      }
      const data = {};
      data.title = 'Discount Summary';
      data.headers = [
        { title: 'Discount Type', type: 'text' },
        { title: 'Promo Code', type: 'text' },
        { title: 'Promo Name', type: 'text' },
        { title: 'Count', type: 'number' },
        { title: 'Register Total', type: 'price' },
      ];
      const discounts = this.selectedReport.summary.discount;
      data.rows = Object.entries(discounts.types)
        .map(([discountType, discount]) =>
          Object.entries(discount).map(([promoCode, promo]) => [
            discountType,
            !promo.code ? promoCode : promo.code,
            promo.name || 'N/A',
            promo.num_orders,
            promo.total,
          ]),
        )
        .flat();
      return data;
    },
    fee() {
      if (!this.report || !this.selectedReport) {
        return null;
      }
      const data = {};
      data.title = 'Fee Summary';
      data.headers = [
        { title: 'Fee Name', type: 'text' },
        { title: 'Cost', type: 'price' },
        { title: 'Count', type: 'number' },
        { title: 'Total Amount', type: 'price' },
      ];
      const { fees } = this.selectedReport.summary;
      data.rows = [];
      if (fees.types && fees.types['Service Fee']) {
        Object.entries(fees.types['Service Fee'].amounts).forEach(([key, value]) => {
          data.rows.push(['Service Fee', key, value.num_orders, value.total]);
        });
      }
      if (fees.types && fees.types['Delivery Fee'] && fees.types['Delivery Fee'].amounts) {
        Object.entries(fees.types['Delivery Fee'].amounts).forEach(([key, value]) => {
          data.rows.push(['Delivery Fee', key, value.num_orders, value.total]);
        });
      }
      return data;
    },
    web() {
      if (!this.report || !this.selectedReport) {
        return null;
      }
      const { web_total } = this.selectedReport.distribution;
      return {
        title: 'Web/Desktop Ordering Sales Summary',
        headers: [
          { title: 'Count', type: 'text' },
          { title: 'Total Amount', type: 'price' },
        ],
        rows: [[web_total?.count || 0, web_total?.total || 0]],
        webStyle: true,
      };
    },
    distribution() {
      if (!this.report || !this.selectedReport) {
        return null;
      }
      const data = {};
      data.title = 'Sales Distribution';
      data.headers = [
        { title: 'Time of Day', type: 'text' },
        { title: 'Count by Order Time', type: 'number' },
        { title: 'Amount by Order Time', type: 'price' },
        { title: 'Count by Prep Time', type: 'number' },
        { title: 'Amount by Prep Time', type: 'price' },
      ];

      const {
        distribution: { time_of_day },
      } = this.selectedReport;

      data.rows = Object.entries(time_of_day.times).map(([time, v]) => [
        time,
        v.num_orders,
        v.total,
        v.num_orders_by_prep_time,
        v.total_by_prep_time,
      ]);
      data.rows.push([
        'Total Daily',
        time_of_day.count_total_daily,
        time_of_day.net_total_daily,
        time_of_day.count_total_daily,
        time_of_day.net_total_daily,
      ]);
      const taxes = time_of_day.taxes || time_of_day.pst + time_of_day.gst;
      data.rows.push(['Taxes', '', taxes, '', taxes]);
      return data;
    },
    locationOverview() {
      if (!this.report || !this.selectedReport) {
        return null;
      }
      const data = {};
      data.title = 'Location Overview';
      data.headers = [
        { title: 'Brand', type: 'text' },
        { title: 'Payment Type', type: 'text' },
        { title: 'Count', type: 'number' },
        { title: 'Subtotal', type: 'price' },
        { title: 'Service Fees', type: 'price' },
        { title: 'Delivery Fees', type: 'price' },
        { title: 'Gross Sales', type: 'price' },
        { title: 'Discount/Contra Revenue', type: 'price' },
        { title: 'Sales Tax', type: 'price' },
        { title: 'Net Sales', type: 'price' },
        { title: 'Registered Total', type: 'price' },
      ];
      const { brands } = this.selectedReport.distribution;
      const brandRows = Object.entries(brands.names).map(([brand, v]) => {
        const { 'Brand Total': brandTotal, ...paymentTypes } = v;
        const arr = Object.entries(paymentTypes).map(([type, tv], idx) => [
          idx === 0 ? brand : '',
          type,
          tv.count,
          tv.subtotal,
          !tv.is_in_market_place ? tv.service_fee : 'NA',
          !tv.is_in_market_place ? tv.delivery_fee : 'NA',
          !tv.is_in_market_place ? tv.gross : 'NA',
          tv.total_discount,
          !tv.is_in_market_place ? tv.taxes : 'NA',
          !tv.is_in_market_place ? tv.net_sales : 'NA',
          !tv.is_in_market_place ? tv.total : 'NA',
        ]);

        // Brand Total
        if (brandTotal) {
          arr.push([
            arr.length === 0 ? brand : '',
            'Brand Total',
            brandTotal.count,
            brandTotal.subtotal,
            !brandTotal.is_in_market_place ? brandTotal.service_fee : 'NA',
            !brandTotal.is_in_market_place ? brandTotal.delivery_fee : 'NA',
            !brandTotal.is_in_market_place ? brandTotal.gross : 'NA',
            brandTotal.total_discount,
            !brandTotal.is_in_market_place ? brandTotal.taxes : 'NA',
            !brandTotal.is_in_market_place ? brandTotal.net_sales : 'NA',
            !brandTotal.is_in_market_place ? brandTotal.total : 'NA',
          ]);
        }
        return arr;
      });
      data.rows = brandRows.flat();
      if (
        this.selectedReport.distribution.market_places &&
        Object.keys(this.selectedReport.distribution.market_places.names).length
      ) {
        const marketPlaces = Object.keys(this.selectedReport.distribution.market_places.names);
        marketPlaces.forEach((marketPlace) => {
          const {
            count,
            subtotal,
            service_fee,
            delivery_fee,
            gross,
            total_discount,
            taxes,
            net_sales,
            total,
          } = this.selectedReport.distribution.market_places.names[marketPlace];
          const marketPlaceTotal = [
            marketPlace,
            'Marketplace Total',
            count,
            subtotal,
            service_fee,
            delivery_fee,
            gross,
            total_discount,
            taxes,
            net_sales,
            total,
          ];
          data.rows.push(marketPlaceTotal);
        });
      }
      if (
        this.selectedReport.distribution.grand_total &&
        this.selectedReport.distribution.grand_total.total
      ) {
        const {
          count,
          sub_total,
          service_fees,
          delivery_fees,
          gross,
          discounts,
          taxes,
          net_sales,
          total,
        } = this.selectedReport.distribution.grand_total;
        const grand = [
          'Grand Total',
          '',
          count,
          sub_total,
          service_fees,
          delivery_fees,
          gross,
          discounts,
          taxes,
          net_sales,
          total,
        ];
        data.rows.push(grand);
      } else {
        const {
          count,
          subtotal,
          service_fee,
          delivery_fee,
          gross,
          discount,
          taxes,
          net_sales,
          register,
        } = this.selectedReport.summary.summary;

        const grand = [
          'Grand Total',
          '',
          count,
          subtotal,
          service_fee,
          delivery_fee,
          gross,
          discount,
          taxes,
          net_sales,
          register,
        ];
        data.rows = brandRows.flat().concat([grand]);
      }
      return data;
    },
    selectedReport() {
      if (!this.report) {
        return null;
      }
      if (this.unitsSelected.length === 1) {
        const report =
          this.report && this.report.reports.find((e) => e.id === this.unitsSelected[0]);
        return report && report.data;
      }

      if (this.unitsSelected.length > 1) {
        const reports = cloneDeep(
          this.report.reports.filter((e) => this.unitsSelected.includes(e.id)),
        );

        const customizer = (a, b) => defaultTo(add(a, b), undefined);
        if (reports.length > 0) {
          const mergedReports = reports
            .slice(1)
            .reduce((prev, curr) => mergeWith(prev, curr, customizer), reports[0]);
          return mergedReports.data;
        }
        return null;
      }

      // by default return all summary unless filtered
      return this.report.summary.data;
    },
    locationTimeZone() {
      try {
        if (
          this.site &&
          this.site.locations &&
          this.site.locations.length > 0 &&
          this.site.locations[0].brands &&
          this.site.locations[0].brands.length > 0
        )
          return this.site.locations[0].brands[0].timezone;
        return DateTime.local().zone.name;
      } catch {
        return DateTime.local().zone.name;
      }
    },
    country() {
      try {
        if (
          this.site &&
          this.site.locations &&
          this.site.locations.length > 0 &&
          this.site.locations[0].address &&
          this.site.locations[0].address.country
        )
          return this.site.locations[0].address.country.toLowerCase();
        return null;
      } catch {
        return null;
      }
    },
    isMarketPlaceReport() {
      if (this.unitsSelected.length === 0) {
        return (
          this.report &&
          this.report.reports.find((report) => report.data && report.data.is_market_place)
        );
      }
      if (this.unitsSelected.length > 0) {
        return this.selectedReport && this.selectedReport.is_market_place;
      }
      return false;
    },
    isWebReport() {
      return (
        this.report &&
        this.report.summary &&
        this.report.summary.data &&
        this.report.summary.data.distribution &&
        this.report.summary.data.distribution.web_total
      );
    },
  },

  methods: {
    ...mapActions('sites', ['getLocationGroup']),
    async fetchSite() {
      this.loading = true;
      try {
        const site = await this.getLocationGroup({
          id: this.site_id,
          nocache: true,
        });
        this.site = site;
      } catch (err) {
        console.error(`Could not get location group info`, err);
        this.$toast.error('Error loading site information');
      }
      this.loading = false;
    },

    async fetchReport() {
      if (!this.reportDates.start || !this.reportDates.end) return;
      this.reportLoading = true;
      try {
        this.report = null;
        const { data } = await this.api.get(
          `/report/eod/group/${this.site_id}?start=${this.reportDates.start}&end=${this.reportDates.end}`,
        );
        this.report = data;
      } catch {
        this.$toast.error('Error loading report information');
      }
      this.reportLoading = false;
    },

    goToSettings() {
      this.$router.push({
        name: 'report-site-settings',
        params: {
          multigroup_id: this.multigroup_id,
          site_id: this.site_id,
        },
      });
    },
  },
  watch: {
    reportDates: {
      handler() {
        this.fetchReport();
      },
      immediate: true,
    },
  },
  async mounted() {
    await this.fetchSite();

    this.$store.commit('adminPanel/setViewTitle', {
      depth: 0,
      title: 'Sales Report',
      to: { name: 'report-sites', params: { multigroup_id: this.multigroup_id } },
      last: false,
    });
    this.$store.commit('adminPanel/setViewTitle', {
      depth: 1,
      title: `${this.site.name}`,
      to: {
        name: 'report-site-details',
        params: { multigroup_id: this.multigroup_id, site_id: this.site_id },
      },
    });
  },
};
</script>
