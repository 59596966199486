import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    { attrs: { wrap: "", "justify-space-between": "", "px-4": "" } },
    [
      _c(VFlex, { attrs: { shrink: "" } }, [
        _c("div", { staticClass: "Subtitle-2-Secondary-Left" }, [
          _vm._v("\n      Reporting Dates\n    ")
        ]),
        _c("div", { staticClass: "reading-hint" }, [
          _vm._v("12:00am-11:59pm " + _vm._s(_vm.locationTimeZone))
        ]),
        _c(
          "div",
          { staticClass: "H4-Selected-On-Surface-High-Emphasis-Left" },
          [_vm._v("\n      " + _vm._s(_vm.dateRange) + "\n    ")]
        )
      ]),
      _c(VFlex, { attrs: { shrink: "" } }, [
        _c("div", { staticClass: "Subtitle-2-Secondary-Left" }, [
          _vm._v("\n      Beginning Reading\n    ")
        ]),
        _c("div", { staticClass: "reading-hint" }, [
          _vm._v("\n      " + _vm._s(_vm.startDisplay) + "\n    ")
        ]),
        _c(
          "div",
          { staticClass: "H4-Selected-On-Surface-High-Emphasis-Left" },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm._f("currency")(_vm.beginningReading)) +
                "\n    "
            )
          ]
        )
      ]),
      _c(VFlex, { attrs: { shrink: "" } }, [
        _c("div", { staticClass: "Subtitle-2-Secondary-Left" }, [
          _vm._v("\n      Ending Reading\n    ")
        ]),
        _c("div", { staticClass: "reading-hint" }, [
          _vm._v("\n      " + _vm._s(_vm.endDisplay) + "\n    ")
        ]),
        _c(
          "div",
          { staticClass: "H4-Selected-On-Surface-High-Emphasis-Left" },
          [
            _vm._v(
              "\n      " + _vm._s(_vm._f("currency")(_vm.endReading)) + "\n    "
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }