<template>
  <v-layout wrap justify-space-between px-4>
    <v-flex shrink>
      <div class="Subtitle-2-Secondary-Left">
        Reporting Dates
      </div>
      <div class="reading-hint">12:00am-11:59pm {{ locationTimeZone }}</div>
      <div class="H4-Selected-On-Surface-High-Emphasis-Left">
        {{ dateRange }}
      </div>
    </v-flex>
    <v-flex shrink>
      <div class="Subtitle-2-Secondary-Left">
        Beginning Reading
      </div>
      <div class="reading-hint">
        {{ startDisplay }}
      </div>
      <div class="H4-Selected-On-Surface-High-Emphasis-Left">
        {{ beginningReading | currency }}
      </div>
    </v-flex>
    <v-flex shrink>
      <div class="Subtitle-2-Secondary-Left">
        Ending Reading
      </div>
      <div class="reading-hint">
        {{ endDisplay }}
      </div>
      <div class="H4-Selected-On-Surface-High-Emphasis-Left">
        {{ endReading | currency }}
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import DateTime from 'luxon/src/datetime.js';

export default {
  props: ['dates', 'report', 'locationTimeZone'],
  computed: {
    startDisplay() {
      return DateTime.fromISO(this.dates.start, {
        zone: this.locationTimeZone || 'local',
      }).toLocaleString(DateTime.DATE_MED);
    },
    endDisplay() {
      return DateTime.fromISO(this.dates.end, {
        zone: this.locationTimeZone || 'local',
      }).toLocaleString(DateTime.DATE_MED);
    },
    dateRange() {
      return this.startDisplay === this.endDisplay
        ? this.startDisplay
        : `${this.startDisplay} - ${this.endDisplay}`;
    },
    beginningReading() {
      if (!this.report) return '';
      return this.report.summary.summary.beginning;
    },
    endReading() {
      if (!this.report) return '';
      return this.report.summary.summary.end;
    },
  },
};
</script>

<style scoped>
.reading-hint {
  font-family: Avenir;
  font-size: 13.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);
}
</style>
