import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-4 pb-4", staticStyle: { overflow: "scroll" } },
    [
      _c(
        VLayout,
        { staticClass: "ma-0", attrs: { wrap: "" } },
        [
          _c(
            VFlex,
            { staticClass: "H6-Secondary-Left ml-2", attrs: { xs12: "" } },
            [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]
          )
        ],
        1
      ),
      _c("table", { staticClass: "eod-table" }, [
        _c("thead", [
          _c(
            "tr",
            [
              _vm._l(_vm.headers, function(header, index) {
                return _c("th", { key: index, class: header.type }, [
                  _vm._v("\n          " + _vm._s(header.title) + "\n        ")
                ])
              }),
              _c("th")
            ],
            2
          )
        ]),
        _c(
          "tbody",
          [
            _vm._l(_vm.rows, function(row, index) {
              return _c(
                "tr",
                { key: index },
                [
                  _vm._l(row, function(cell, index) {
                    return _c(
                      "td",
                      {
                        key: index,
                        class: _vm.webStyle
                          ? ["web", _vm.headers[index].type]
                          : _vm.headers[index].type
                      },
                      [
                        _vm.headers[index] &&
                        _vm.headers[index].type === "price" &&
                        typeof cell !== "string"
                          ? [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm._f("currency")(cell)) +
                                  "\n          "
                              )
                            ]
                          : [
                              _vm._v(
                                "\n            " + _vm._s(cell) + "\n          "
                              )
                            ]
                      ],
                      2
                    )
                  }),
                  _c("td")
                ],
                2
              )
            }),
            _vm._t("default")
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }