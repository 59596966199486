import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "v-card-custom" },
    [
      _c(
        VLayout,
        { attrs: { wrap: "", "justify-space-between": "" } },
        [
          _c(
            VFlex,
            { attrs: { shrink: "", xs4: "" } },
            [
              _c("range-date-picker", {
                attrs: {
                  dates: _vm.dates,
                  reportDateValue: _vm.reportDateValue,
                  reportDateLabel: "Report Date",
                  allowedDates: _vm.allowedDates
                },
                on: {
                  "update:dates": function($event) {
                    _vm.dates = $event
                  }
                }
              })
            ],
            1
          ),
          _c(
            VFlex,
            { attrs: { shrink: "" } },
            [
              _c(
                VBtn,
                { attrs: { flat: "" }, on: { click: _vm.showFilters } },
                [
                  _c(VIcon, [_vm._v("mdi-filter-variant")]),
                  _vm._v("Filters ")
                ],
                1
              ),
              _c(
                VBtn,
                {
                  attrs: {
                    color: "primary",
                    disabled: _vm.reportExporting,
                    loading: _vm.reportExporting
                  },
                  on: { click: _vm.download }
                },
                [
                  _c(VIcon, [_vm._v("mdi-upload")]),
                  _vm._v("Export\n      ")
                ],
                1
              ),
              _vm.oneMarketIntegrationEnabled
                ? _c(
                    VBtn,
                    {
                      attrs: {
                        color: "primary",
                        disabled: _vm.reportExportingOneMarketSales,
                        loading: _vm.reportExportingOneMarketSales
                      },
                      on: { click: _vm.downloadOneMarketSales }
                    },
                    [
                      _c(VIcon, [_vm._v("mdi-upload")]),
                      _vm._v("Export OneMarket Sales\n      ")
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.unitsSelectedProxy.length > 0
        ? [
            _c(VDivider),
            _c(
              VLayout,
              { attrs: { wrap: "", "align-center": "" } },
              [
                _c(
                  VFlex,
                  {
                    staticClass: "Body-1-Black-High-Emphasis-Left",
                    attrs: { shrink: "" }
                  },
                  [_vm._v("\n        Filters:\n      ")]
                ),
                _c(
                  VFlex,
                  _vm._l(_vm.unitsSelectedProxy, function(unit) {
                    return _c(
                      VChip,
                      {
                        key: unit,
                        attrs: { close: "", label: "" },
                        on: {
                          input: function($event) {
                            return _vm.removeUnit(unit)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.getUnitName(unit)) +
                            "\n        "
                        )
                      ]
                    )
                  }),
                  1
                )
              ],
              1
            )
          ]
        : _vm._e(),
      _c(
        VNavigationDrawer,
        {
          attrs: { temporary: "", app: "", right: "" },
          model: {
            value: _vm.drawer,
            callback: function($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer"
          }
        },
        [
          _c("div", { staticClass: "H5-Primary-Left pa-3" }, [
            _vm._v("Filters")
          ]),
          _c(VDivider, { attrs: { light: "" } }),
          _c(
            VLayout,
            { staticClass: "ma-2", attrs: { wrap: "" } },
            [
              _c(VFlex, { attrs: { xs12: "" } }, [
                _c("span", { staticClass: "Body-1-Secondary-Left" }, [
                  _vm._v("\n          Unit/Cost Centre\n        ")
                ])
              ])
            ],
            1
          ),
          _c(
            VList,
            _vm._l(_vm.site.locations, function(unit) {
              return _c(
                VListTile,
                { key: unit.id },
                [
                  _c(
                    VListTileAction,
                    [
                      _c(VCheckbox, {
                        attrs: { value: unit.id },
                        model: {
                          value: _vm.unitsSelectedProxy,
                          callback: function($$v) {
                            _vm.unitsSelectedProxy = $$v
                          },
                          expression: "unitsSelectedProxy"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VListTileContent,
                    [
                      unit.meta && unit.meta.unit
                        ? _c(VListTileTitle, [
                            _vm._v("Unit " + _vm._s(unit.meta.unit))
                          ])
                        : _c(VListTileTitle, [_vm._v(_vm._s(unit.name))])
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }