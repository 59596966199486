<template>
  <v-card class="v-card-custom">
    <v-layout wrap justify-space-between>
      <v-flex shrink xs4>
        <range-date-picker
          :dates.sync="dates"
          :reportDateValue="reportDateValue"
          reportDateLabel="Report Date"
          :allowedDates="allowedDates"
        />
      </v-flex>
      <v-flex shrink>
        <v-btn flat @click="showFilters"> <v-icon>mdi-filter-variant</v-icon>Filters </v-btn>
        <v-btn
          color="primary"
          @click="download"
          :disabled="reportExporting"
          :loading="reportExporting"
        >
          <v-icon>mdi-upload</v-icon>Export
        </v-btn>
        <v-btn
          color="primary"
          @click="downloadOneMarketSales"
          :disabled="reportExportingOneMarketSales"
          :loading="reportExportingOneMarketSales"
          v-if="oneMarketIntegrationEnabled"
        >
          <v-icon>mdi-upload</v-icon>Export OneMarket Sales
        </v-btn>
      </v-flex>
    </v-layout>
    <template v-if="unitsSelectedProxy.length > 0">
      <v-divider />
      <v-layout wrap align-center>
        <v-flex class="Body-1-Black-High-Emphasis-Left" shrink>
          Filters:
        </v-flex>
        <v-flex>
          <v-chip
            close
            v-for="unit in unitsSelectedProxy"
            :key="unit"
            label
            @input="removeUnit(unit)"
          >
            {{ getUnitName(unit) }}
          </v-chip>
        </v-flex>
      </v-layout>
    </template>
    <v-navigation-drawer v-model="drawer" temporary app right>
      <div class="H5-Primary-Left pa-3">Filters</div>
      <v-divider light></v-divider>
      <v-layout class="ma-2" wrap>
        <v-flex xs12>
          <span class="Body-1-Secondary-Left">
            Unit/Cost Centre
          </span>
        </v-flex>
      </v-layout>
      <v-list>
        <v-list-tile v-for="unit of site.locations" :key="unit.id">
          <v-list-tile-action>
            <v-checkbox v-model="unitsSelectedProxy" :value="unit.id"></v-checkbox>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title v-if="unit.meta && unit.meta.unit"
              >Unit {{ unit.meta.unit }}</v-list-tile-title
            >
            <v-list-tile-title v-else>{{ unit.name }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import DateTime from 'luxon/src/datetime.js';
import isEqual from 'lodash/isEqual';
import { save } from 'save-file';
import { mapGetters, mapState } from 'vuex';
import rangeDatePicker from '@/components/rangeDatePicker';
import { featureFlagNames } from '@/constants';

export default {
  props: ['site', 'locationTimeZone', 'unitsSelected'],
  components: {
    rangeDatePicker,
  },
  data: () => ({
    drawer: false,
    menu: false,
    reportExporting: false,
    reportExportingOneMarketSales: false,
  }),

  computed: {
    ...mapState('reports', ['reportDates']),
    ...mapGetters('splitio', ['isFeatureOn']),
    dates: {
      get() {
        return this.reportDates;
      },
      set(_dates) {
        if (!isEqual(this.reportDates, _dates)) {
          this.$store.commit('reports/setReportDates', _dates);
        }
        const dates_query_param = `${_dates.start}_${_dates.end}`;
        if (!this.$route.query || this.$route.query.dates !== dates_query_param) {
          this.$router.replace({
            name: 'report-site-details',
            params: {
              site_id: this.$route.params.site_id,
              multigroup_id: this.$route.params.multigroup_id,
            },
            query: {
              dates: dates_query_param,
            },
          });
        }
      },
    },
    unitsSelectedProxy: {
      get() {
        return this.unitsSelected;
      },
      set(_units) {
        this.$emit('update:unitsSelected', _units);
      },
    },
    reportDateValue() {
      return this.dates.start === this.dates.end
        ? this.dates.start
        : `${this.dates.start}  -  ${this.dates.end}`;
    },
    oneMarketIntegrationEnabled() {
      return (
        this.isFeatureOn(featureFlagNames.oneMarketIntegration) &&
        this.site?.meta?.partner_menu_import_enabled
      );
    },
  },

  methods: {
    removeUnit(unit) {
      this.unitsSelectedProxy = this.unitsSelectedProxy.filter((proxyUnit) => proxyUnit !== unit);
    },

    showFilters() {
      this.drawer = true;
    },

    allowedDates(_date) {
      const selected = DateTime.fromISO(_date);
      const yesterday = DateTime.local()
        .setZone(this.locationTimeZone)
        .minus({ days: 1 });
      const sixMonthsAgo = yesterday.minus({ months: 6 });
      return selected >= sixMonthsAgo && selected <= yesterday;
    },

    getUnitName(unit) {
      const location = this.site.locations.find((e) => e.id === unit);
      if (location && location.meta && location.meta.unit) {
        return `Unit ${location.meta.unit}`;
      }
      return location.name;
    },

    async download() {
      this.reportExporting = true;
      try {
        let queryString = `?format=xlsx&start=${this.dates.start}&end=${this.dates.end}`;
        if (this.unitsSelectedProxy && this.unitsSelectedProxy.length > 0) {
          queryString += `&locations=${this.unitsSelectedProxy.join(',')}`;
        }

        const { data } = await this.api.get(
          `/report/eod/group/${this.$route.params.site_id}${queryString}`,
        );

        this.reportExporting = false;
        await save(data.file, `${this.site.name}-sales-report.${data.format}`);
      } catch (e) {
        console.error(e);
        this.$toast.error('Error exporting report information');
        this.reportExporting = false;
      }
    },

    async downloadOneMarketSales() {
      if (this.unitsSelectedProxy && this.unitsSelectedProxy.length === 0) {
        this.$toast.warning('At least one unit should be selected to OneMarket Report Sales');
        return;
      }

      this.reportExportingOneMarketSales = true;
      try {
        let queryString = `?format=xlsx&start=${this.dates.start}&end=${this.dates.end}`;
        if (this.unitsSelectedProxy && this.unitsSelectedProxy.length > 0) {
          queryString += `&locations=${this.unitsSelectedProxy.join(',')}`;
        }

        queryString += `&partner=onemarket`;

        const { data } = await this.api.get(
          `/report/eod/group/${this.$route.params.site_id}/items${queryString}`,
        );

        this.reportExportingOneMarketSales = false;
        await save(
          data.file,
          `${this.site.name}-sales-report-one-market-integration.${data.format}`,
        );
      } catch (e) {
        console.error(e);
        this.$toast.error('Error exporting report information');
        this.reportExportingOneMarketSales = false;
      }
    },

    setInitialReportDates() {
      const yesterday = DateTime.local()
        .setZone(this.locationTimeZone)
        .minus({ days: 1 })
        .toISODate();
      const startEndDates = {
        start: yesterday,
        end: yesterday,
      };
      if (this.$route.query.dates) {
        const queryDates = this.$route.query.dates.split('_');
        const [start, end] = queryDates;
        startEndDates.start = start;
        startEndDates.end = end;
      }
      this.dates = startEndDates;
    },
  },
  mounted() {
    this.setInitialReportDates();
  },
};
</script>

<style scoped>
.datepicker >>> .mdi-calendar-range:hover::before {
  color: #3076d8;
}
.datepicker >>> input,
.datepicker >>> .v-text-field__slot,
.datepicker >>> .v-input__slot {
  cursor: pointer;
}
</style>
