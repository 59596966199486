<template>
  <div class="pt-4 pb-4" style="overflow: scroll;">
    <v-layout class="ma-0" wrap>
      <v-flex class="H6-Secondary-Left ml-2" xs12>
        {{ title }}
      </v-flex>
    </v-layout>
    <table class="eod-table">
      <thead>
        <tr>
          <th v-for="(header, index) of headers" :key="index" :class="header.type">
            {{ header.title }}
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) of rows" :key="index">
          <td
            v-for="(cell, index) of row"
            :key="index"
            :class="webStyle ? ['web', headers[index].type] : headers[index].type"
          >
            <template
              v-if="headers[index] && headers[index].type === 'price' && typeof cell !== 'string'"
            >
              {{ cell | currency }}
            </template>
            <template v-else>
              {{ cell }}
            </template>
          </td>
          <td></td>
        </tr>
        <slot></slot>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ['headers', 'rows', 'title', 'webStyle'],
};
</script>

<style scoped>
.eod-table tbody > tr:nth-child(odd) {
  background: #f4f4f4;
}

.eod-table {
  width: 100%;
  min-width: 0px;
  border-collapse: collapse;
}

.eod-table th {
  min-width: 80px;
  padding: 5px 20px;
  font-family: Avenir;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.15px;
  color: #090c9b;
  white-space: nowrap;
}
.eod-table td {
  font-family: Avenir;
  white-space: nowrap;
  padding: 5px 20px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.87);
}
.eod-table .number {
  text-align: right;
}
.eod-table .price {
  text-align: right;
}
.eod-table .text {
  text-align: left;
}

.eod-table th:last-of-type {
  width: 60%;
}
.eod-table .web:first-child {
  padding-left: 3em;
}
</style>
