import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { "grid-list-xl": "" } },
    [
      _c(
        "loading-wrapper",
        { attrs: { loading: _vm.loading } },
        [
          _c(
            VLayout,
            {
              attrs: {
                wrap: "",
                "justify-space-between": "",
                "align-content-center": ""
              }
            },
            [
              _c(
                VFlex,
                { attrs: { shrink: "", xs9: "" } },
                [_c("view-title")],
                1
              ),
              _c(VFlex, { attrs: { shrink: "" } }, [
                _c(
                  "div",
                  { staticClass: "FAB-Extended-PrimaryColor-Enabled" },
                  [
                    _c(
                      VBtn,
                      {
                        attrs: { type: "submit", flat: "" },
                        on: { click: _vm.goToSettings }
                      },
                      [
                        _c(VIcon, { attrs: { left: "", dark: "" } }, [
                          _vm._v("mdi-settings")
                        ]),
                        _vm._v("Configuration\n          ")
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            VLayout,
            { attrs: { wrap: "" } },
            [
              _c(
                VFlex,
                { attrs: { xs12: "" } },
                [
                  _c("filters", {
                    attrs: {
                      site: _vm.site,
                      locationTimeZone: _vm.locationTimeZone,
                      unitsSelected: _vm.unitsSelected
                    },
                    on: {
                      "update:unitsSelected": function($event) {
                        _vm.unitsSelected = $event
                      },
                      "update:units-selected": function($event) {
                        _vm.unitsSelected = $event
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            VLayout,
            [
              _c(
                "loading-wrapper",
                { attrs: { loading: _vm.reportLoading } },
                [
                  _c(
                    VFlex,
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        VTabs,
                        { attrs: { "hide-slider": "" } },
                        [
                          _c(VTab, { attrs: { href: "#summary" } }, [
                            _vm._v("Summary")
                          ]),
                          _c(VTab, { attrs: { href: "#distribution" } }, [
                            _vm._v("Sales Distribution")
                          ]),
                          _c(
                            VTabItem,
                            { attrs: { value: "summary" } },
                            [
                              _c(
                                VCard,
                                { attrs: { flat: "" } },
                                [
                                  _c("readings", {
                                    attrs: {
                                      dates: _vm.reportDates,
                                      report: _vm.selectedReport,
                                      locationTimeZone: _vm.locationTimeZone
                                    }
                                  }),
                                  !_vm.selectedReport
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "H6-Selected-On-Surface-Medium-Emphasis-Center"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  No reports available for selected units\n                "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "report-table",
                                    _vm._b(
                                      {},
                                      "report-table",
                                      _vm.summary,
                                      false
                                    )
                                  ),
                                  _c(
                                    "report-table",
                                    _vm._b(
                                      {},
                                      "report-table",
                                      _vm.disbursements,
                                      false
                                    )
                                  ),
                                  _c(
                                    "report-table",
                                    _vm._b(
                                      {},
                                      "report-table",
                                      _vm.discounts,
                                      false
                                    )
                                  ),
                                  _c(
                                    "report-table",
                                    _vm._b({}, "report-table", _vm.fee, false)
                                  ),
                                  _vm.isWebReport
                                    ? _c(
                                        "report-table",
                                        _vm._b(
                                          {},
                                          "report-table",
                                          _vm.web,
                                          false
                                        )
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            VTabItem,
                            { attrs: { value: "distribution" } },
                            [
                              _c(
                                VCard,
                                { attrs: { flat: "" } },
                                [
                                  !_vm.selectedReport
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "H6-Selected-On-Surface-Medium-Emphasis-Center"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  No reports available for selected units\n                "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "report-table",
                                    _vm._b(
                                      {},
                                      "report-table",
                                      _vm.distribution,
                                      false
                                    )
                                  ),
                                  _c(
                                    "report-table",
                                    _vm._b(
                                      {},
                                      "report-table",
                                      _vm.locationOverview,
                                      false
                                    )
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.isMarketPlaceReport
            ? _c("div", [
                _c(
                  "h5",
                  {
                    staticClass:
                      "Subtitle-1-Black-Medium-Emphasis-Left font-weight-normal"
                  },
                  [
                    _vm._v(
                      "\n        * NA - Sales distribution is not available for brands set up in a Marketplace\n      "
                    )
                  ]
                )
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }